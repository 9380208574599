import React from "react";
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Logout from "./components/Logout/Logout";
import Home from "./pages/Home";


export default function AppRoutes() {
    const location = useLocation();

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/home" element={<Home />} />
            <Route path="/*" element={<Navigate to="/" replace state={{
                referrer: {
                    pathname: location.pathname,
                    search: location.search,
                },
            }} />} />
        </Routes>
    );
}