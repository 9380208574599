import {Close, Visibility, VisibilityOff} from "@mui/icons-material";
import {Box, Button, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, Link, MenuItem, Typography} from "@mui/material";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {sleep} from "../../util/Sleep";
import CCInputLabel from "../Common/CCInputLabel";
import CCPulse from "../Common/CCPulse";
import CCSelect from "../Common/CCSelect";
import CCTextField from "../Common/CCTextField";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginMain.module.scss";
import "./css/LoginMain.scss";
import {useLoginContext} from "./Login";
import enlilImage from "../../assets/logo-5cd08655.svg";
import {Blue3} from "../../theme/styles";
import useAppContext from "../../util/AppContext";

export default function LoginMain() {

    const {sessionContext} = useAppContext();

    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [displayedCompany, setDisplayedCompany] = useState("");
    const [displayedCompanies, setDisplayedCompanies] = useState([]);
    const [displayedVisibility, setDisplayedVisibility] = useState(null);

    const {
        updatePreSessionState,
        login,
    } = useLoginContext();

    useEffect(() => {
        updatePreSessionState(state => {
            return {
                ...state,
                email: email,
                password: password,
                visibility: displayedVisibility
            };
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, password, displayedVisibility]);

    function default_visibility(companies, company_name) {

        if (Object.keys(companies).length > 0 && company_name in companies) {

            const company = companies[company_name][0];
            const companyUSID = company["companyUSID"];

            if (company["Positions"].length > 0) {
                const position = company["Positions"][0];
                return `${companyUSID}:Position:${position["USID"]}`;
            }

            if (company["UserGroups"].length > 0) {
                const userGroup = company["UserGroups"][0];
                return `${companyUSID}:UserGroup:${userGroup["USID"]}`;
            }

            if (company["Workgroups"].length > 0) {
                const workgroup = company["Workgroups"][0];
                return `${companyUSID}:Workgroup:${workgroup["USID"]}`;
            }
        }

        return null;
    }

    async function submit() {

        const startAt = Date.now();

        const waitVisualMin = async () => {
            const waitDiff = 2000 - (Date.now() - startAt);
            waitDiff > 0 && (await sleep(waitDiff));
        };

        setSubmitting(true);

        const response = await login();

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        // maintenance outage is in effect
        if (response.status === 503 && response.data.Code === "CC-CF78-4C2A") {
            window.location.reload();
            return;
        }

        if (response.status !== 200) {

            await waitVisualMin();

            setSubmitting(false);

            if (response.status === 429) {
                if (response.data.Code === "CC-746A-4E73") {
                    setErrorText("Exceeded 5 failed login attempts in 24 hours");
                    return;
                }
            }

            if (response.status === 400) {

                if (response.data.Code === "CC-11C9-41C9") {
                    if (response.data["FailCount"] === 8) {
                        setErrorText("Invalid username or password. 8th consecutive failure. 2 more attempts allowed.");
                    } else if (response.data["FailCount"] === 9) {
                        setErrorText("Invalid username or password. 9th consecutive failure. 1 more attempt allowed.");
                    } else if (response.data["FailCount"] === 10) {
                        setErrorText("Invalid username or password. Account locked.");
                    } else {
                        setErrorText("Invalid username or password.");
                    }
                    return;
                }

                if (response.data.Code === "CC-1242-4AAC") {
                    setErrorText("User is deactivated. Please contact administrator.");
                    return;
                }
                if (response.data.Code === "CC-1242-4WWC") {
                    setErrorText("User has not confirmed their account. Please contact administrator.");
                    return;
                }
            }

            if (response.status === 401 || response.status === 403) {
                setErrorText("Invalid username or password");
            } else {
                setErrorText("Service temporarily unavailable");
            }

            return;
        }

        // multiple companies
        if (response.data.Code === "CC-ADC7-49A2" || response.data.Code === "CC-E5EB-43D5") {

            setSubmitting(false);

            const companies = response.data["Companies"];

            Object.keys(companies).forEach(k => {
                companies[k] = _.sortBy(companies[k], x => x["CompanyName"]);
            });

            const displayedCompanies = Object.keys(companies).sort();
            const displayedCompany = displayedCompanies[0];
            const displayedVisibility = default_visibility(companies, displayedCompany);

            setCompanies(companies);

            setDisplayedCompany(displayedCompany);
            setDisplayedCompanies(displayedCompanies);
            setDisplayedVisibility(displayedVisibility);

            setShowPassword(false);

            return;
        }

        // noinspection UnnecessaryReturnStatementJS
        return;
    }

    let loginFormToPresent = null;

    if (displayedCompanies.length === 0) {
        loginFormToPresent = (
            <>
                <Typography className={css_shared.Title}>Login to your company</Typography>
                <CCInputLabel>Email</CCInputLabel>
                <CCTextField
                    value={email}
                    placeholder="Enter your email"
                    onChange={async e => setEmail(e.target.value)}
                />
                <CCInputLabel>Password</CCInputLabel>
                <CCTextField
                    value={password}
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    helperText={<span className="Mui-error" style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</span>}
                    onChange={async e => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={
                        submitting || email.length === 0 || password.length === 0
                    }
                    onClick={() => {
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Login</span>
                    {submitting ? <CCPulse className={css_self.Pulse}/> : undefined}
                </Button>
            </>
        );
    }

    if (displayedCompanies.length >= 1) {

        let menuItems = [];

        for (let i = 0; i < companies[displayedCompany][0]["Positions"].length; i++) {
            if (i === 0) {
                menuItems.push(<MenuItem disabled value={"positions"} key={i + 1000}>Positions</MenuItem>);
            }
            const companyUSID = companies[displayedCompany][0]["CompanyUSID"];
            const position = companies[displayedCompany][0]["Positions"][i];
            menuItems.push(<MenuItem value={`${companyUSID}:Position:${position["USID"]}`} key={i}>&emsp;{position["Name"]}</MenuItem>);
        }

        for (let i = 0; i < companies[displayedCompany][0]["UserGroups"].length; i++) {
            if (i === 0) {
                menuItems.push(<MenuItem disabled value={"userGroups"} key={i + 1500}>User Groups</MenuItem>);
            }
            const companyUSID = companies[displayedCompany][0]["CompanyUSID"];
            const userGroup = companies[displayedCompany][0]["UserGroups"][i];
            menuItems.push(<MenuItem value={`${companyUSID}:UserGroups:${userGroup["USID"]}`} key={i + 50}>&emsp;{userGroup["Name"]}</MenuItem>);
        }

        for (let i = 0; i < companies[displayedCompany][0]["Workgroups"].length; i++) {
            if (i === 0) {
                menuItems.push(<MenuItem disabled value={"workgroups"} key={i + 2000}>Workgroups</MenuItem>);
            }
            const companyUSID = companies[displayedCompany][0]["CompanyUSID"];
            const workgroup = companies[displayedCompany][0]["Workgroups"][i];
            menuItems.push(<MenuItem value={`${companyUSID}:Workgroup:${workgroup["USID"]}`} key={i + 100}>&emsp;{workgroup["Name"]}</MenuItem>);
        }

        loginFormToPresent = (
            <>
                {displayedCompanies.length === 1 ? (
                    <Typography className={css_shared.Title}>Select your persona</Typography>
                ) : (
                    <>
                        <Typography className={css_shared.Title}>Select your company and persona</Typography>
                        <CCInputLabel>Company</CCInputLabel>
                        <CCSelect
                            size={"small"}
                            fullWidth={true}
                            value={displayedCompany}
                            onChange={e => {
                                setDisplayedCompany(e.target.value);
                                setDisplayedVisibility(default_visibility(companies, e.target.value));
                            }}
                        >
                            {displayedCompanies.map(company => (
                                <MenuItem value={company} key={`select-company-${company}`}>{company}</MenuItem>
                            ))}
                        </CCSelect>
                    </>
                )}
                <CCInputLabel>Persona</CCInputLabel>
                <CCSelect
                    size={"small"}
                    fullWidth={true}
                    value={displayedVisibility}
                    onChange={e => setDisplayedVisibility(e.target.value)}
                >
                    {menuItems}
                </CCSelect>
                <Button
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={
                        submitting ||
                        (displayedCompanies.length > 0 && (displayedVisibility === null || displayedVisibility.length === 0))
                    }
                    onClick={() => {
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Select</span>
                    {submitting ? <CCPulse className={css_self.Pulse} /> : undefined}
                </Button>
            </>
        );
    }

    return (
        <Dialog maxWidth="md" open={sessionContext.showLoginDialog} scroll="body">
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={() => {
                    setEmail("")
                    setPassword("")
                    setDisplayedCompanies([]);
                    setDisplayedCompany("");

                    sessionContext.setShowLoginDialog(false);
                    sessionContext.isLoggedIn = false;
                }}>
                  <Close/>
                </IconButton>
            </Box>
            <DialogContent style={{padding: "0 16px"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div>
                        <img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/>
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <span style={{marginLeft: "65px", fontSize: 25, fontWeight: 400, color: Blue3}}>| ETL</span>
                    </div>
                </div>
                {loginFormToPresent}
                <Typography className={css_self.HelpText}>
                    <Link variant="inherit" color="inherit" component={RouterLink} to="/help" replace={true}>Trouble logging in?</Link>
                </Typography>
                {displayedCompanies.length > 0 ? (
                    <Typography className={css_self.HelpText}>
                        <Link variant="inherit" color="inherit" component={RouterLink} to="#" replace={true} onClick={() => window.location.reload()}>Start over</Link>
                    </Typography>
                ) : null}
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    );

}
